import * as types from "../types/affiliate";
import { call, put, takeEvery } from "redux-saga/effects";
import { getDataUser } from "../api/affiliate";
import { affiliateActions } from "../actions";


function* getRequest(action) {
	try {
		const res = yield call(getDataUser, action.payload);
		console.log(res);
		yield put(
			affiliateActions.getUserSuccess(res.data)
		);
	} catch (error) {
		yield put(affiliateActions.getUserFail(error));
	}
}


function* affiliateWatcher() {
	yield takeEvery(types.GET_USER_REQUEST, getRequest);
}
export { affiliateWatcher };