import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { layoutActions } from "../../../../redux/actions";
import { Tooltip, withStyles } from "@material-ui/core";

const ItemMenuTootip = (props) => {
    const { icon, title, to } = props

    const dispatch = useDispatch()
    const history = useHistory()

    const active = history.location.pathname === to

    if (active) {
        dispatch(layoutActions.setPageNow(title));
    }

    return (<Href to={to}>
        <FakeTooltip arrow title={title} placement="right">
            <Container active={active}>{icon}</Container>
        </FakeTooltip>
    </Href>
    )
}

const FakeTooltip = withStyles((theme) => ({
    arrow: {
        color: "#303030",
    },
    tooltip: {
        backgroundColor: "#303030",
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: "0.2em 0.5em",
    },
}))(Tooltip);

export default ItemMenuTootip;

const Container = styled.div`
    width: calc( 100% - 1rem );
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-radius: 3px;
    margin: .5rem 0;
    svg, span {
            color: white;
    }
    span {
        color: white;
    }

    :hover {
        background-color: #4e7991;
    }

    ${props => props.active ? (
        `
            :hover{
                background-color: white;
                svg, span {
                    color: #2f424d;
                }
                span {
                    color: white;
                }
            }
            background-color: white;
            svg, span {
                color: #2f424d;
            }
            span {
                color: white;
            }
            
            `
    )
        :
        null
    }
    
`

const Href = styled(Link)`
    text-decoration: none;
`

const Title = styled.div`
    width: auto;
    padding-left: 1rem;
    text-decoration: none;
    color: white;

    ${props => props.active ? (
        `   
            color: #2f424d;
            `
    )
        :
        null
    }
`