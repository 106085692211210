import { Avatar } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const InfoUser = props => {
    const { affiliate } = props

    return <User>
    <Section>
        <Avatar>{affiliate.nombre[0]}</Avatar>
    </Section>
    <Section>
        <Subtitle>NOMBRE</Subtitle>
        <Value>{affiliate.nombre}</Value>
    </Section>
    <Section>
        <Subtitle>TIPO DOC.</Subtitle>
        <Value>{affiliate.tipo_documento || affiliate?.tipo_documento_id?.name}</Value>
    </Section>
    <Section>
        <Subtitle>DOCUMENTO</Subtitle>
        <Value>{affiliate.numero_documento}</Value>
    </Section>
</User>
}

export default InfoUser;

const User = styled.div`
    width: auto;
    height: 50px;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const Section = styled.section`
    width: auto;
    padding: 0 1rem;
`

const Subtitle = styled.div`
    font-size: .8rem;
    color: #777777;
    font-weight: 700;
`

const Value = styled.div`
    font-size: .9rem;
`