import * as types from "../types/layout";

const actions = {};

actions.setPageNow = (payload) => {
	return {
		type: types.SET_PAGE_NOW,
		payload: payload,
	};
};

actions.setMenuMode = (payload) => {
	return {
		type: types.SET_MENU_MODE,
		payload: payload,
	};
};

actions.setMenuMobile = (payload) => {
	return {
		type: types.SET_MENU_MOBILE,
		payload: payload,
	};
};

export { actions };