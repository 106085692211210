import React, { useState } from 'react'
import styled from 'styled-components'
import { cancelShift } from '../../../../redux/api/shifts';
import { ButtonRed } from '../../../common/ButtonsGenerics';
import PageLoading from '../../../common/PageLoading';
import { Text } from '../../../common/Text';


export default function CancelShift(props) {
    const { shiftId, affiliateId, handleResult } = props

    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        const data = {
            "turno_id": shiftId,
            "afiliado_id": affiliateId
        }
        const request = await cancelShift(data)
        if(request.error) {
            setLoading(false)
            handleResult(false)
        }
        else{
            setLoading(false)
            handleResult(true)
        }
        // handleClose()
    }

    return (
        <ConfirmShiftWrapper>
            <Header>
                <LogoWrapper>
                    <Text fontWeight="600" color="white" fontSize="3rem">?</Text>
                </LogoWrapper>
            </Header>
            <Body>
                <Text fontWeight="600" color="#555555" fontSize="2rem">¿Desea cancelar turno?</Text>
            </Body>
            {!loading ? (
                <Footer>
                    <ButtonRed onClick={handleSubmit}>Cancelar turno</ButtonRed>
                </Footer>
            ) : (
                <PageLoading w="100%" h="100px" />
            )}
        </ConfirmShiftWrapper>
    )
}

const ConfirmShiftWrapper = styled.div`
    min-width: 450px;
    min-height: 250px;
    @media (max-width: 800px) {
        min-width: auto;
    }
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Body = styled.div`
    width: 100%;
    margin: 3rem 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const LogoWrapper = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e34d4b;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`