import axios from "axios";

export async function getProfesional(id) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/turno/search_professionals?especialidad_id=${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getDataShift(speciality, profesional) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/turno/professional_schedule?especialidad_id=${speciality}&profesional_id=${profesional}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function createShift(body) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/turno/request`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function cancelShift(body) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/turno/cancel`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}


export async function getShifts(id) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/turno/get_turnos_afiliado?afiliado_id=${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}
