import { useEffect, useState } from "react"

export const useForm = (initialState = null) => {
    const [form, setForm] = useState(initialState)
    const [status, setStatus] = useState(false)

    useEffect(() => {
        let statusVar = true
        for (const k in form) {
            if (form[k] === null) {
                statusVar = false
            }
        }
        setStatus(statusVar)
    }, [form])

    const handleFormInput = (e) => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    }

    return [form, handleFormInput, status]
}
