import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from "styled-components";
import { layoutActions } from "../../../../redux/actions";

const AccordionMenu = (props) => {
    const { icon, title, submenus, isMobile = false } = props

    const dispatch = useDispatch()
    const history = useHistory()

    const [active, setActive] = useState(false)

    useEffect(() => {
        submenus.forEach(menu => {
            if (history.location.pathname === `/${menu.to}`) {
                setActive(true)
            }
        }) 
    }, [active,submenus,history.location.pathname])

    if(active){
        dispatch(layoutActions.setPageNow(title));
    }

    if (isMobile) {
        return (<AccordionWrapper>
            <AccordionSummaryWrapper
                active={active}
                expandIcon={<ExpandMoreIcon color="secondary"/>}
            >
                <Container>{icon}<Title>{title}</Title></Container>
            </AccordionSummaryWrapper>
            <AccordionDetailsWrapper>
                {submenus.map((menu,i) => {
                    return <LinkAcc onClick={() => dispatch(layoutActions.setMenuMode(false))} key={i} to={`/${menu.to}`}>- {menu.name}</LinkAcc>
                })}
            </AccordionDetailsWrapper>
          </AccordionWrapper>
        )
    }

    return (<AccordionWrapper>
        <AccordionSummaryWrapper
            active={active}
            expandIcon={<ExpandMoreIcon color="secondary"/>}
        >
            <Container>{icon}<Title>{title}</Title></Container>
        </AccordionSummaryWrapper>
        <AccordionDetailsWrapper>
            {submenus.map((menu,i) => {
                return <LinkAcc key={i} to={`/${menu.to}`}>- {menu.name}</LinkAcc>
            })}
        </AccordionDetailsWrapper>
      </AccordionWrapper>
    )
}

export default AccordionMenu;

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    svg, span {
            color: white;
    }
    span {
        color: white;
    }
`

const AccordionWrapper = styled(Accordion)`
    background-color: #00000000;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    :last-child{
        border-radius: 0px;
    }
    :before{
        background-color: #00000000;
    }
    .Mui-expanded{
        height: 35px;
        min-height: 35px;
        max-height: 35px;
    }
`

const AccordionSummaryWrapper = styled(AccordionSummary)`
    margin: 0;
    width: calc( 100% - 2rem );
    display: flex;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-radius: 3px;
    :hover{
        background-color: #4e7991;
    }
    .Mui-expanded{
        height: 35px;
        min-height: 35px;
        max-height: 35px;
    }
    ${
        props => props.active ? (
            `
            :hover{
                background-color: white;
                border-radius: 3px
                svg, span {
                    color: #2f424d;
                }
                div {
                    color: #2f424d;
                }
            }
            background-color: white;
            border-radius: 3px
            }
            svg, span {
                color: #2f424d;
            }
            div {
                color: #2f424d;
            }
            
            `
        )
        :
        null}
`

const AccordionDetailsWrapper = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
`

const Title = styled.div`
    width: auto;
    padding-left: 1rem;
    text-decoration: none;
    color: white;
`

const LinkAcc = styled(Link)`
    text-decoration: none;
    color: white;
    width: 100%;
    height: 30px;
    font-size: .9rem;
    padding-left: .4rem;
    display: flex;
    align-items: center;
    border-radius: 3px;
    :hover{
        background-color: #4e7991;
    }
`