import axios from "axios";
import createPublicApiInstance from '../api';

export function getDataUser(payload) {
	//return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/afiliado/${payload}`);
	const apiInstance = createPublicApiInstance(); 
	if (!apiInstance) {
		throw new Error('API instance is not available');
	}
	return apiInstance.get(`/afiliado/${payload}`);
}

export async function updateAffiliate(id, body) {
	
	try {
		const apiInstance = createPublicApiInstance(); 
		if (!apiInstance) {
			throw new Error('API instance is not available');
		}
		// const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/afiliado/${id}`, body);
		const req = await apiInstance.post(`/afiliado/${id}`, body);
		return req.data
	} catch (error) {
		return { error }
	}
}

export async function resetPassword(body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/afiliado/reset_password`, body);
		return req.data
	} catch (error) {
		return { error }
	}
}

export async function changePassword(body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/afiliado/change_password`, body);
		return req.data
	} catch (error) {
		return { error }
	}
}

export async function changePasswordToken(token, body) {
	const config = {
		headers: {
			Authorization: 'Bearer ' + token
		}
	}
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/afiliado/change_password`, body, config);
		return req.data
	} catch (error) {
		return { error }
	}
}