import React, { useState } from "react";
import styled from "styled-components";
import { ButtonTransparent } from "../../common/ButtonsGenerics";
import CardGeneric from "../../common/CardGeneric";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import FormNewTurn from "../../ui/organisms/shifts/FormNewTurn";
import NewTurn from "../../ui/organisms/shifts/NewTurn";


const TakeTurn = props => {
    const { handleBackPage, handleLastPage } = props

    const [shifts, setShifts] = useState(null)
    const [form, setForm] = useState(null)

    const handleShifts = async (data, form) => {
        setShifts(data)
        setForm(form)
    }

    return <TakeTurnWrapper>
        <ArrowBackContainer>
            <ButtonTransparent onClick={handleBackPage}><ArrowBackIcon/><NameButton>Atras</NameButton></ButtonTransparent>
        </ArrowBackContainer>
        <CardGeneric icon={<SearchIcon/>} title="Reservar turno">
            <FormNewTurn handleShifts={handleShifts}/>
        </CardGeneric>
        {shifts && (
            <CardGeneric icon={<PermContactCalendarIcon/>}>
                <NewTurn handleLastPage={handleLastPage} shifts={shifts} form={form}/>
            </CardGeneric>
        )}
    </TakeTurnWrapper>
}

export default TakeTurn

const TakeTurnWrapper = styled.div`
    width: 100%;
`

const ArrowBackContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NameButton = styled.div`
    margin-left: 1rem;
    font-size: 1rem;
`