import React from "react";
import styled from "styled-components";
import FormLogin from "../../components/templates/auth/FormLogin";
import logo from "../../assets/images/logo_azul.png";
import afiliado from "../../assets/images/icono_afiliado.png";
import bg from "../../assets/images/fondo2.png";
import footer from "../../assets/images/footer.png";
import { Img } from "../../components/common/Image";
import { Text, Span } from "../../components/common/Text";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

const Login = () => {

    const history = useHistory()

    const handleRegister = () => history.push('/auth/register')

    return <LoginWrapper>
        <Container>
            <LogoWrapper>
                <Img w="180px" src={logo}/>
                <Img w="230px" src={afiliado}/>
            </LogoWrapper>
            <FormLogin />
            <br/>
            <Button onClick={() => history.push('../auth/reset')}>Olvide mi contraseña</Button>
            <Wrapper>
                <Text fontWeight="600" fontSize="1rem" color="#92b6ca">¿Aún no tienes cuenta? <Span onClick={handleRegister} color="#333">Regístrate aquí.</Span></Text>
            </Wrapper>
        </Container>
        <Footer>
            <ImgFooter src={footer}/>
        </Footer>
    </LoginWrapper>
}

export default Login;

const LoginWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #528bab11;
    background-image: url(${bg});
    @media (max-width: 768px) {
		align-items: flex-start;
        height: auto;
	}
`

const Container = styled.div`
    width: 350px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 0px 12px 0px rgba(152,152,152,0.76);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(152,152,152,0.76);
    -moz-box-shadow: 0px 0px 12px 0px rgba(152,152,152,0.76);
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 9;
    margin-bottom: 3rem;
    @media (max-width: 768px) {
		width: calc(100% - 3rem);
        height: auto;
        padding: 2rem 3rem;
        margin: 2rem 1rem 0 1rem;
	}
`

const LogoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

const Wrapper = styled.div`
    width: 100%;
    margin-top: 2rem;
    text-align: center;
    span{
        cursor: pointer;
    }
`

const Footer = styled.footer`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    background-color: white;
    position: absolute;
    bottom: 0;
`

const ImgFooter = styled(Img)`
    width: auto;
    height: 60px;
    @media (max-width: 768px) {
        display: none;
	}
`