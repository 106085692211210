import React from "react";
import { ButtonTransparent } from "../../components/common/ButtonsGenerics";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import CardGeneric from "../../components/common/CardGeneric";
import PaymentIcon from '@material-ui/icons/Payment';
import { Text } from "../../components/common/Text";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StatusPay = () => {

    let query = useQuery();
    const isErrorExist = query.get('error')

    return <>
        <ArrowBackContainer>
            <ButtonTransparent onClick={() => window.open( window.location.origin + '/current-debt' )}><ArrowBackIcon /><NameButton>Volver a Cuenta Corriente</NameButton></ButtonTransparent>
        </ArrowBackContainer>
        <CardGeneric icon={<PaymentIcon />}>
            {!!isErrorExist ? (
                <Container>
                    <Circle color="#a83725"><CloseIcon/></Circle>
                    <Text color="#333" fontSize="18px" fontWeight="bold">¡Ocurrió un error en el pago!</Text>
                    <Text textAlign="center" color="#555">Detalle: {isErrorExist}</Text>
                </Container>
            ) :
                (<Container>
                    <Circle color="#66ba45"><CheckIcon/></Circle>
                    <Text color="#333" fontSize="18px" fontWeight="bold">¡El pago se procesó con éxito!</Text>
                    <Text textAlign="center" color="#555">El sistema puede tener una demora en actualizar</Text>
                </Container>)}
        </CardGeneric>
    </>;
};

export default StatusPay;

const ArrowBackContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NameButton = styled.div`
    margin-left: 1rem;
    font-size: 1rem;
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin: 18px 0;
`

const Circle = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: ${p => p.color};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    svg{
        font-size: 48px;
    }
`