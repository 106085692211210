import React, { useState } from "react";
import styled from "styled-components";
import CardGeneric from "../../components/common/CardGeneric";
import TableGeneric from "../../components/common/TableGeneric";
import ProfileAffiliate from "../../components/ui/organisms/affiliate/Profile";
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import CreateIcon from '@material-ui/icons/Create';
import DialogGeneric from "../../components/common/DialogGeneric";
import { ButtonBlue, ButtonGreen } from "../../components/common/ButtonsGenerics";
import ViewData from "../../components/ui/organisms/affiliate/ViewData";
import FormUpdateAffiliate from "../../components/common/user/FormUpdateAffiliate";

const Affiliate = () => {

    const [show, setShow] = useState(false)
    const [closeDialog, setCloseDialog] = useState(false)

    const { family, user } = useSelector(state => state.auth)

    const columns = [
        { id: 'tipo_doc', label: 'Tipo doc.' },
        { id: 'numero_documento', label: 'N° Documento' },
        { id: 'nombre', label: 'Nombre' },
        { id: 'apellido', label: 'Apellido' },
        { id: 'parentesco', label: 'Parentesco' },
        { id: 'estado', label: 'Estado' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const tableFamily = () => {
        if (family) {
            let result = []
            family.map((row, i) => {
                if (user.titular === true) {
                    return result.push(
                        {
                            id: i,
                            tipo_doc: row.tipo_documento_id.name,
                            acciones: <Actions>
                                <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} button={<ButtonBlue><PersonIcon /></ButtonBlue>} component={<ViewData handleClose={handleForceClose} id={row.afiliado_id}/>}/>
                                <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} button={<ButtonGreen><CreateIcon /></ButtonGreen>} component={<FormUpdateAffiliate handleClose={handleForceClose} id={row.afiliado_id}/>}/>
                            </Actions>,
                            ...row
                        }
                    )
                }
                else {
                    return result.push(
                        {
                            id: i,
                            tipo_doc: row.tipo_documento_id.name,
                            acciones: <Actions>
                                <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} button={<ButtonBlue><PersonIcon /></ButtonBlue>} component={<ViewData handleClose={handleForceClose} id={row.afiliado_id}/>}/>
                            </Actions>,
                            ...row
                        }
                    )
                }

            })
            return result
        }
        else {
            return []
        }
    }

    const handleShow = () => {
        setShow(!show)
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
        setShow(false)
    }


    return <AffiliateWrapper>
        <CardGeneric title={"Afiliado"} icon={<PersonIcon />} handleClick={handleShow} button={Button} buttonName={"Editar"}>
            <ProfileAffiliate />
            <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} handleShow={handleForceClose} show={show} button={true} component={<FormUpdateAffiliate headline={true} handleClose={handleForceClose} id={user.afiliado_id}/>}/>
        </CardGeneric>
        {family.length > 0 && (
            <CardGeneric title={"Grupo familiar"} icon={<GroupIcon />}>
                <TableGeneric columns={columns} rows={tableFamily()} pageSize={10} />
            </CardGeneric>
        )}
    </AffiliateWrapper>
}

export default Affiliate;

const AffiliateWrapper = styled.div`
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`