import React, { useEffect, useState } from "react";
import CardGeneric from "../../components/common/CardGeneric";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import styled from "styled-components";
import InfoDebt from "../../components/ui/organisms/current-account/InfoDebt";
import { useSelector } from "react-redux";
import TableGeneric from "../../components/common/TableGeneric";
import { getCurrentAccount } from "../../redux/api/account";
import { Text } from "../../components/common/Text";
import PageLoading from "../../components/common/PageLoading";
import SearchGeneric from "../../components/common/SearchGeneric";
import { Button } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ButtonTransparent } from "../../components/common/ButtonsGenerics";
import PaysDebt from "../../components/templates/current-account/PaysDebt";
import { formatCurrency } from "../../helpers/currency.format.helper";
import TabsComponent from "../../components/common/TabsComponent";

const CurrentDebt = () => {

    const { user } = useSelector(state => state.auth)

    // HOOKS
    const [rowsRequest, setRows] = useState(null)
    const [rowsRequest2, setRows2] = useState(null)
    const [filtered, setFiltered] = useState(null);
    const [filtered2, setFiltered2] = useState(null);
    const [data, setData] = useState(null)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(null)
    const [idClient, setIdClient] = useState(null)
    const [tab, setTab] = useState(0);

    const urlPay = `${process.env.REACT_APP_PAY}&import=${total}&client_ref=${idClient}&email=${user.email}&dni=${user.numero_documento}`


    useEffect(() => {
        getAccount(user.afiliado_id)
    }, [])

    const getAccount = async (id) => {
        const request = await getCurrentAccount(id)
        if (request.error) {
            return
        }
        setData(request)
        renderTable(JSON.parse(request?.detalles || []))
        renderTable2(JSON.parse(request?.detalles_enviados_a_cobrar || []))
    }

    const renderTable = (rowsRequest) => {
        if (rowsRequest) {
            let result = []
            rowsRequest.map((row) => {
                return result.push(
                    {
                        ...row,
                        importe: row.importe >= 0 ? <Text color="#944030" fontWeight="bold">$ {formatCurrency(row?.importe.toFixed(2) * 1)}</Text> : <Text color="#71a84e" fontWeight="bold">${formatCurrency(row?.importe.toFixed(2) * 1)}</Text>,
                        importeText: row.importe,
                        dateI: new Date(row.create_date).toLocaleDateString(),
                        tipo: row.importe >= 0 ? <Text color="#944030" fontWeight="700">DÉBITO</Text> : <Text color="#71a84e" fontWeight="700">CRÉDITO</Text>,
                        tipoText: row.importe >= 0 ? 'debito' : 'credito',
                        nro: row.id,
                        tipo_2: row.tipo || '-',
                    }
                )

            })
            setRows(result)
            return result
        }
        else {
            setRows([])
            return []
        }
    }

    const renderTable2 = (rowsRequest) => {
        if (rowsRequest) {
            let result = []
            rowsRequest.map((row) => {
                return result.push(
                    {
                        ...row,
                        importe: row.saldo >= 0 ? <Text color="#944030" fontWeight="bold">$ {formatCurrency(row?.saldo.toFixed(2) * 1)}</Text> : <Text color="#71a84e" fontWeight="bold">${formatCurrency(row?.saldo.toFixed(2) * 1)}</Text>,
                        importeText: row.importe,
                        dateI: new Date(row.create_date).toLocaleDateString(),
                        tipo: row.importe >= 0 ? <Text color="#944030" fontWeight="700">DÉBITO</Text> : <Text color="#71a84e" fontWeight="700">CRÉDITO</Text>,
                        tipoText: row.importe >= 0 ? 'debito' : 'credito',
                        nro: row.id,
                        tipo_2: row.tipo || '-',
                    }
                )

            })
            setRows2(result)
            return result
        }
        else {
            setRows2([])
            return []
        }
    }

    const handleFilter = filtered => setFiltered(filtered)
    const handleFilter2 = filtered => setFiltered2(filtered)

    // TABLE
    const columns = [
        { id: 'dateI', label: 'Fecha' },
        { id: 'afiliado_name', label: 'Afiliado' },
        { id: 'tipo', label: 'T. Movimiento' },
        // { id: 'nro', label: 'N° Movimiento' },
        { id: 'descripcion', label: 'Descripción' },
        { id: 'tipo_2', label: 'Tipo' },
        { id: 'importe', label: 'Importe' },
    ]

    const columns2 = [
        { id: 'dateI', label: 'Fecha' },
        { id: 'afiliado_name', label: 'Afiliado' },
        { id: 'tipo', label: 'T. Movimiento' },
        // { id: 'nro', label: 'N° Movimiento' },
        { id: 'descripcion', label: 'Descripción' },
        { id: 'tipo_2', label: 'Tipo' },
        { id: 'importe', label: 'Saldo' },
        { id: 'medio_de_cobro', label: 'Medio de cobro' }
    ]

    const tabs = [
        { label: `Movimientos pendientes`, id: 0 },
        { label: `Movimientos en proceso de cobro`, id: 1 },
    ];

    const handlePay = (pay) => {
        setPage(pay)
    }

    const handleTab = (e, value) => {
        setTab(value);
    };

    const handlePagePay = (total, id) => {
        setTotal(total)
        setIdClient(id)
        setPage('iframe')
    }

    // console.log(rowsRequest);

    if (!data || !rowsRequest) {
        return <PageLoading h="100px" />
    }

    if (page === 'pay') {
        return <CurrentWrapper>
            <ArrowBackContainer>
                <ButtonTransparent onClick={() => handlePay(null)}><ArrowBackIcon /><NameButton>Atras</NameButton></ButtonTransparent>
            </ArrowBackContainer>
            <CardGeneric title={"Seleccionar facturas"} icon={<AccountBalanceWalletIcon />}>
                <PaysDebt handlePagePay={handlePagePay} />
            </CardGeneric>
        </CurrentWrapper>
    }

    if (page === 'iframe') {
        return <CurrentWrapper>
            <ArrowBackContainer>
                <ButtonTransparent onClick={() => handlePay('pay')}><ArrowBackIcon /><NameButton>Atras</NameButton></ButtonTransparent>
            </ArrowBackContainer>
            <CardGeneric title={"Datos de pago"} icon={<AccountBalanceWalletIcon />}>
                <Iframe src={urlPay} />
            </CardGeneric>
        </CurrentWrapper>
    }

    return <CurrentWrapper>
        {/* <CardGeneric title={"Deuda actual"} icon={<AccountBalanceWalletIcon/>} handleClick={() => handlePay('pay')} button={Button} buttonName={"Pagar"}> */}
        <CardGeneric title={`Cuenta corriente - ${tab === 0 ? 'movimientos pendientes' : 'movimientos en proceso de cobro'}`} icon={<AccountBalanceWalletIcon />} handleClick={() => handlePay('pay')} button={tab === 0 ? Button : Div} buttonName={tab === 0 && data?.saldo > 0 ? "Pagar" : false}>
            <InfoDebt info={data} user={user} />
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            {tab === 0 && (<>
                <SearchGeneric title="Buscar en cuenta corriente" handleValues={handleFilter} filterNames={['cod', 'importeText', 'nro', 'tipoText', 'afiliado_name']} values={rowsRequest} />
                <TableGeneric columns={columns} rows={filtered || rowsRequest} pageSize={10} />
            </>)}
            {tab === 1 && (<>
                <TextInfo>Los movimientos que se visualizan a continuación se encuentran en proceso de cobro, el cual puede demorar algunos días. Una vez realizado el cobro se verá impactado en su cuenta corriente.</TextInfo>
                <SearchGeneric title="Buscar en cuenta corriente" handleValues={handleFilter2} filterNames={['cod', 'importeText', 'nro', 'tipoText', 'afiliado_name', 'medio_de_cobro']} values={rowsRequest2} />
                <TableGeneric columns={columns2} rows={filtered2 || rowsRequest2} pageSize={10} />
            </>)}
        </CardGeneric>
    </CurrentWrapper>
}

export default CurrentDebt;

const CurrentWrapper = styled.div`
`

const ArrowBackContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NameButton = styled.div`
    margin-left: 1rem;
    font-size: 1rem;
`

const Iframe = styled.iframe`
    width: 100%;
    height: 600px;
    border: none;
`

const TextInfo = styled.div`
    background-color: #eee;
    border-radius: 12px;
    padding: 8px;
    font-weight: bold;
    color: #444;
    margin: 16px 0;
`

const Div = styled.div`
    display: none;
`