import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import CardGeneric from "../../components/common/CardGeneric";
import { Text } from "../../components/common/Text";
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useDispatch, useSelector } from "react-redux";
import { utilsActions } from "../../redux/actions";
import PageLoading from "../../components/common/PageLoading";
import { ButtonGreen } from "../../components/common/ButtonsGenerics";
import { searchProvider } from "../../redux/api/utils";
import TableGeneric from "../../components/common/TableGeneric";
import { Dialog, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import ErrorPage from "../../components/common/ErrorPage";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloseIcon from '@material-ui/icons/Close';

const Internal = () => {

    // VARIABLES
    const columns = [
        { id: 'name', label: 'Prestador' },
        { id: 'especialidad', label: 'Especialidad' },
        { id: 'action', label: 'Agenda' },
    ]

    // REDUX
    const specialties = useSelector(state => state.utils.specialties)
    const dispatch = useDispatch()

    // STATE
    const [currentSpecialties, setCurrentSpecialties] = useState(null)
    const [currentProvider, setCurrentProvider] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [diary, setDiary] = useState(null)
    const [option, setOption] = useState(1)
    const [providerName, setProviderName] = useState(null)

    const handleClickOpen = (data) => {
        setDiary(data)
        setOpen(true);
    };

    const handleClose = () => {
        setDiary(null)
        setOpen(false);
    };

    // EFFECTS
    useEffect(() => {
        if (specialties === null) {
            dispatch(utilsActions.specialtiesRequest())
        }
    }, [])

    // FUNCTIONS
    const handleSpecialties = (e, value) => {
        if (value !== null) {
            setCurrentSpecialties(value.id)
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        const provider = await searchProvider(option, currentSpecialties, providerName);
        if (provider?.error?.response?.status === 404) {
            setCurrentProvider([])
        }
        else if (provider.error) {
            setCurrentProvider(false)
        }
        else if (provider[0]?.statusCode === 404) {
            setCurrentProvider([])
        }
        else {
            setCurrentProvider(sanitizerTable(provider))
        }
        setLoading(false)
    }

    const sanitizerTable = (array) => {
        if (array) {
            let result = []
            array.map((row, i) => {
                return result.push(
                    {
                        ...row,
                        name: `${row.nombre} ${row.apellido}`,
                        street: `${row.street} ${row.direccion_numero}`,
                        especialidad: row.especialidad.toUpperCase(),
                        action: <ButtonGreen onClick={() => handleClickOpen(row)}><CalendarTodayIcon /></ButtonGreen>
                    }
                )
            })
            return result
        }
        else {
            return []
        }
    }

    // RETURNS
    if (specialties === null) {
        return <PageLoading h="100px" />
    }


    return <InternalWrapper>
        <CardGeneric title={"Busqueda prestador interno"} icon={<LocalHospitalIcon />}>
                <Text fontSize=".9rem" fontWeight="700" color="#555555">CRITERIO DE BÚSQUEDA</Text>
            <Container>
                <FormControlFake>
                    <InputLabel id="1">Opciones de busqueda</InputLabel>
                    <Select
                        labelId="1"
                        value={option}
                        onChange={e => {
                            setOption(e.target.value)
                            setCurrentSpecialties(null)
                            setProviderName(null)
                        }}
                        name="affiliate"
                    >
                        {[{ id: 1, name: 'Especialidad' }, { id: 2, name: 'Prestador' }].map(member => <MenuItem value={member.id}>{`${member.name}`}</MenuItem>)}
                    </Select>
                </FormControlFake>
                {option === 1 && <FormControlFake>
                    <Autocomplete
                        id="combo-box-demo"
                        options={specialties}
                        getOptionLabel={(option) => option.name}
                        onChange={handleSpecialties}

                        renderInput={(params) => <TextField {...params} value={currentSpecialties} label="Seleccione una especialidad" variant="standard" />}
                    />
                </FormControlFake>}
                {option === 2 && <FormControlFake>
                    <TextField fullWidth label="Buscar por prestador" value={providerName} onChange={e => setProviderName(e.target.value)}/>
                </FormControlFake>}
            </Container>
            {(currentSpecialties !== null || !!providerName) && (
                <Footer>
                    {!loading ? (
                        <ButtonGreen onClick={handleSubmit}>BUSCAR</ButtonGreen>
                    ) :
                        (
                            <PageLoading w="100%" h="auto" />
                        )}
                </Footer>
            )}
        </CardGeneric>
        {!!currentProvider && (
            <CardGeneric title={"Resultados de búsqueda"} icon={<PageviewIcon />}>
                <TableGeneric columns={columns} rows={currentProvider} pageSize={10} />
            </CardGeneric>
        )}
        {currentProvider === false && <ErrorPage />}
        <Dialog onClose={handleClose} open={open}>
            {diary && (
                <ContainerDialog>
                    <Header>
                        <Text fontWeight="bold" fontSize="18px">{diary.prestacion} | {diary.nombre} {diary.apellido}</Text>
                        <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                    </Header>
                    <Grid grid="1fr 1fr 1fr">
                        <TextFake fontWeight="bold">Dia</TextFake>
                        <TextFake fontWeight="bold">Horario inicial</TextFake>
                        <TextFake fontWeight="bold">Horario final</TextFake>
                        {JSON.parse(diary.agenda).map(d => (<>
                            <TextFake>{d.dia}</TextFake>
                            <TextFake>{d.hora_desde}hs</TextFake>
                            <TextFake>{d.hora_hasta}hs</TextFake>
                        </>))}
                    </Grid>
                </ContainerDialog>
            )}
        </Dialog>
    </InternalWrapper>
}

export default Internal

const InternalWrapper = styled.div`
    width: 100%;
    height: 100px;
    background-color: red;
`

const Container = styled.div`
    /* padding: 3rem; */
    width: 100%;
    height: auto;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    @media(max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const FormControlFake = styled(FormControl)`
    width: calc(100%);
    margin: 12px 0;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
`

const ContainerDialog = styled.div`
    padding: 25px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${p => p.grid || 1};
`

const TextFake = styled(Text)`
    border-bottom: 1px solid black;
    padding: 6px 0;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`