import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useSnackBar } from "../../../hooks/useSnackBar";
import { authActions } from "../../../redux/actions";
import { getDataUser, updateAffiliate } from "../../../redux/api/affiliate";
import { getCountries, getDepartament, getLocation, getStates, getLocalidad } from "../../../redux/api/utils";
import { ButtonBlue, ButtonTransparent } from "../ButtonsGenerics";
import DefaultPhone from "../DefaultPhone";
import PageLoading from "../PageLoading";
import { SnackBarGeneric } from "../SnackBarGeneric";
import { Text } from "../Text";

const FormUpdateAffiliate = (props) => {
    const { id, handleClose, headline = false } = props

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const [form, setForm] = useState(null)
    const [loading, setLoading] = useState(false)

    // UTILS SELECTS
    const [zip, setzip] = useState(null)
    const [countries, setCountries] = useState(null)
    const [states, setStates] = useState(null)
    const [departaments, setDepartaments] = useState(null)
    const [localidades, setLocalidades] = useState(null)
    const [formErrorArray, setFormErrorArray] = useState([])

    // Redux
    const dispatch = useDispatch()

    // EFFECTS
    useEffect(() => {
        getAffiliate(id)
        getCountriesService()
    }, [])



    useEffect(() => {

        if (form?.country) {
            getStatesService(form.country.id)
            if (form.state) {
                getDepartamentsService(form.state.id)
                if (form.departamento) {
                    getLocalidadService(form.departamento.id)
                }
            }
        }
    }, [form?.country?.id])


    // FUNCTIONS
    const getAffiliate = async id => {
        try {
            const { data } = await getDataUser(id)
            setForm(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getCountriesService = async () => {
        try {
            const { data } = await getCountries()
            setCountries(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getStatesService = async (id_country) => {
        try {
            const { data } = await getStates(id_country)
            setStates(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getDepartamentsService = async (id_state) => {

        if (id_state === null) {
            return
        }
        try {
            const { data } = await getDepartament(id_state)
            setDepartaments(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getLocalidadService = async (id_department) => {
        if (id_department === null) {
            return
        }
        try {
            const { data } = await getLocalidad(id_department)
            setLocalidades(data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSumbitByCP = (e) => {
        if (e.target.value.length <= 4) {
            setForm(state => ({ ...state, zip: e.target.value }))
        }
    }

    const handleChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleChangePhone = value => {
        setForm({ ...form, 'phone': value })
    }

    const handleChangeMobile = value => {
        setForm({ ...form, 'mobile': value })
    }

    const handleSelectState = e => {
        const state_name = states.find((state) => state.id == e.target.value).name
        setForm({ ...form, departamento: null, [e.target.name]: { id: e.target.value, name: state_name } })
        getDepartamentsService(e.target.value)
    }

    const handleSelectDepartament = e => {
        const department_name = departaments.find((department) => department.id == e.target.value).name
        setForm({ ...form, localidad: null, location: null, [e.target.name]: { id: e.target.value, name: department_name } })
        getLocalidadService(e.target.value)
    }

    const handleSelectLocalidad = e => {
        const localidad_name = localidades.find((localidad) => localidad.id == e.target.value).name
        setForm({ ...form, [e.target.name]: { id: e.target.value, name: localidad_name } })
    }

    const isFormOK = () => {

        setFormErrorArray([])
        let emailOk = true;
        let zipOk = true;
        let direccionOk = true;
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


        if (!form.email.match(validRegex)) {
            setFormErrorArray((current) => [...current, ' email'])
            emailOk = false;
        }

        if (!form.zip) {
            setFormErrorArray((current) => [...current, ' codigo postal'])
            zipOk = false;
        }

        if (!form.direccion) {
            setFormErrorArray((current) => [...current, ' direccion'])
            direccionOk = false;
        }

        if (emailOk && zipOk && direccionOk) {
            return true;

        }

        return false;
    }

    const handleSubmit = async () => {

        if (!isFormOK()) {
            return
        }

        const data = {
            phone: form.phone || "",
            mobile: form.mobile || "",
            direccion: form.direccion || "",
            zip: form.zip || "5500",
            country_id: form.country?.id || 0,
            state_id: form.state?.id || 0,
            departamento_id: form.departamento?.id || 0,
            localidad_id: form.localidad?.id || 0,
            email: form.email
            // localidad_id: 0
        }
        setLoading(true)
        const request = await updateAffiliate(id, data)
        if (request.error) {
            openSnackBar(
                "error",
                "¡Error en actualizar al afiliado!"
            );
            setLoading(false)
        }
        else {
            openSnackBar(
                "success",
                "Se actualizó correctamente"
            );
            setLoading(false)
            // Si es el titular actualizo los datos del titular en redux
            if (headline) {
                setDataUserSession(form)
            }
            setTimeout(() => {
                handleClose()
            }, 1500);
        }
    }

    const setDataUserSession = (form) => {
        const newForm = {
            phone: form.phone || "",
            mobile: form.mobile || "",
            direccion: form.direccion || "",
            zip: form.zip || "5500",
            country: form.country?.name || 0,
            state: form.state?.name || 0,
            departamento: form.departamento?.name || 0,
            localidad: form.localidad?.name || 0,
            email: form.email
        }

        dispatch(authActions.changeDataUser(newForm))
    }

    if (form === null) {
        return <PageLoading h="200px" w="300px" />
    }

    return <FormUpdateAffiliateWrapper>
        <Header>
            <Text color="#555555" fontWeight="700" fontSize="1.5rem">Editar datos de contacto</Text>
        </Header>
        {
            formErrorArray.length > 1 &&
            (
                <span>Por favor revise los campos :{formErrorArray.join(',')}</span>
            )
        }
        {
            formErrorArray.length == 1 &&
            (
                <span>Por favor revise el campo :{formErrorArray[0]}</span>
            )
        }
        <Body id="overflow">
            <TextField variant="outlined" fullWidth disabled value={`${form.nombre} ${form.apellido}`} label="Nombre" />
            <TextFieldFake fullWidth variant="outlined" type="text" onChange={handleChange} name="email" value={form?.email} label="Correo eletrónico" />
            <DefaultPhone InputProps={{ inputProps: { name: 'mobile' } }} onChange={handleChangeMobile} name="mobile" value={form?.mobile} specialLabel="Teléfono celular" />
            <DefaultPhone InputProps={{ inputProps: { name: 'phone' } }} onChange={handleChangePhone} name="phone" value={form?.phone} specialLabel="Teléfono fijo" />
            <TextFieldFake fullWidth variant="outlined" onChange={handleChange} name="direccion" value={form?.direccion} label="Dirección" />
            <TextFieldFake
                variant="outlined"
                fullWidth

                value={form?.zip}
                onChange={handleSumbitByCP} label="Código Postal"
            />
            {(
                <>
                    <Grid>
                        {/* PAISES */}
                        {countries && (
                            <FormControl>
                                <InputLabel>Pais</InputLabel>
                                <Select variant="outlined" fullWidth disabled name="country" value={form.country ? form.country.id : 0} label="Pais">
                                    {countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )}
                        {states && (
                            <FormControl>
                                <InputLabel>Provincia</InputLabel>
                                <Select variant="outlined" fullWidth onChange={handleSelectState} name="state" value={form.state ? form.state.id : ""} label="Provincia">
                                    {states.map(state => <MenuItem value={state.id}>{state.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid>
                        {departaments && <FormControl>
                            <InputLabel>Departamento</InputLabel>
                            <Select variant="outlined" fullWidth onChange={handleSelectDepartament} name="departamento" value={form.departamento ? form.departamento.id : ""} label="Departamento">
                                {departaments.map(dpto => <MenuItem value={dpto.id}>{dpto.name}</MenuItem>)}
                            </Select>
                        </FormControl>}
                        {localidades && <FormControl>
                            <InputLabel>Localidad</InputLabel>
                            <Select variant="outlined" fullWidth onChange={handleSelectLocalidad} name="localidad" value={form.localidad ? form.localidad.id : ""} label="Localidad">
                                {localidades.map(localidad => <MenuItem value={localidad.id}>{localidad.name}</MenuItem>)}
                            </Select>
                        </FormControl>}
                    </Grid>
                </>
            )}

            <br />

        </Body>
        <Footer>
            {!loading ? <div>
                <ButtonTransparent onClick={handleClose}>Cancelar</ButtonTransparent>
                <ButtonBlue onClick={handleSubmit}>Guardar</ButtonBlue>
            </div>
                : (<PageLoading w="100%" h="100px" />)}
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </FormUpdateAffiliateWrapper>
}

export default FormUpdateAffiliate;

const FormUpdateAffiliateWrapper = styled.div`
    min-width: 350px;
    width: 500px;
    min-height: 100px;
    height: 100%;
    #overflow{
        overflow-y: auto;
        padding: 8px;
    }
    @media (max-width: 768px) {
		width: auto;
	}
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #999999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const Footer = styled.div`
    width: 100%;
    max-height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const Body = styled.div`
    margin: 2rem 0;
    max-height: 350px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
	}
`

const TextFieldFake = styled(TextField)`
`