import { Button, IconButton, Input, InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { validateDocument } from "../../../helpers/InputValidate.helper";
import { authActions } from "../../../redux/actions";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useSnackBar } from "../../../hooks/useSnackBar";
import { SnackBarGeneric } from "../../common/SnackBarGeneric";
import PageLoading from "../../common/PageLoading";

const FormLogin = () => {

    // Hooks
    const history = useHistory()
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    // State
    const [user, setUser] = useState("")
    const [userError, setUserError] = useState(true)
    const [pass, setPass] = useState("")
    // const [userPass, setPassError] = useState(false)
    const [typeInputPass, setTypeInputPass] = useState("password")

    // Redux
    const dispatch = useDispatch()
    const { authToken, error, loading } = useSelector(state => state.auth)

    // Effect
    useEffect(() => {
        if (error) {
            let errorMessage = null
            if (error?.response?.status === 403) errorMessage = "¡Credenciales incorrectas!"
            openSnackBar(
                "error",
                errorMessage !== null ? errorMessage : "¡Ocurrió un error inesperado!"
            );
        }

    }, [error])

    // Functions
    const handleUser = (e) => {
        setUserError(validateDocument(e.target.value))
        setUser(e.target.value)
    }

    const handlePass = (e) => {
        setPass(e.target.value)
    }

    const handleSubmit = () => {
        dispatch(authActions.loginUserRequest({ user, password: pass }))
        setUser("")
        setPass("")
        if (authToken !== null) {
            history.push("/")
        }

    }

    const handleTypeViewPass = () => {
        setTypeInputPass(typeInputPass === "password" ? "text" : "password")
    }

    return <FormLoginWrapper>
        <Form>
            <TextField type="number" value={user} helperText={userError ? "" : "Cantidad de digitos inválido"} error={!userError} onChange={handleUser} label="Documento" />
            <InputFake
                type={typeInputPass}
                value={pass}
                onChange={handlePass}
                placeholder="Contraseña"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTypeViewPass}
                            onMouseDown={handleTypeViewPass}
                        >
                            {typeInputPass === "password" ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Form>
        {!loading ? (
            <Button disabled={user !== "" && pass !== "" && userError ? false : true} onClick={handleSubmit} variant="contained" color="primary">
                Iniciar Sesión
            </Button>
        ) : (
            <ButtonLoading disabled={true} variant="contained" color="primary">
                <PageLoading color="#777777" w="100%" h="100%" scale=".5" h="20px" />
                <div>Cargando</div>
            </ButtonLoading>
        )}


        {/* Snack */}
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </FormLoginWrapper>
}

export default FormLogin

const FormLoginWrapper = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Form = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
`

const InputFake = styled(Input)`
    margin: 2rem 0;
`

const ButtonLoading = styled(Button)`
    display: flex;
    width: 150px;
    justify-content: space-between;
`