export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validateDocument = (document) => {
    let doc = parseInt(document)
    if (typeof doc === "number") {
        if (doc.toString().length === 6 || doc.toString().length === 7 || doc.toString().length === 8) {
            return true
        }
        else{
            return false
        }
    }
    return false
}