
import DniForm from "./dniForm"
import React, { useState } from "react";
import PendingList from "./pendingList";
import CardGeneric from "../../../components/common/CardGeneric";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import styled from "styled-components";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ButtonTransparent } from "../../../components/common/ButtonsGenerics";


const DirectPaymentProcess = () => {

    //Estados
    const [user, setUser] = useState("")
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(null)
    const [idClient, setIdClient] = useState(null)
    
    //constantes
    const urlPay = `${process.env.REACT_APP_RQUEST_PAY}&import=${total}&client_ref=${idClient}`

    //Funciones
    const handleGoToPage = (page) => {
        setPage(page)
    }

    const handlePagePay = (total, id) => {
        setTotal(total)
        setIdClient(id)
        setPage('iframe')
    }

    const handlePay = (pay) => {
        setPage(pay)
    }

    // Returns

    if (page === 'pendigRequests') {
        return <PendingList user={user} handlePagePay={handlePagePay} gotoPage={handleGoToPage} />
    }

    if (page === 'iframe') {
        return <CurrentWrapper>
            <ArrowBackContainer>
                <ButtonTransparent onClick={() => handlePay('pay')}><ArrowBackIcon /><NameButton>Atras</NameButton></ButtonTransparent>
            </ArrowBackContainer>
            <CardGeneric title={"Datos de pago"} icon={<AccountBalanceWalletIcon />}>
                <Iframe src={urlPay} />
            </CardGeneric>
        </CurrentWrapper>
    }

    return <DniForm
        user={user}
        setUser={setUser}
        handleDniSubmit={handleGoToPage} />

}

export default DirectPaymentProcess

const CurrentWrapper = styled.div`
width: 100%;
`


const ArrowBackContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NameButton = styled.div`
    margin-left: 1rem;
    font-size: 1rem;
`

const Iframe = styled.iframe`
    width: 600px;
    height: 600px;
    border: none;
`

