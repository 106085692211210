import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useSnackBar } from '../../../hooks/useSnackBar'
import { resetPassword } from '../../../redux/api/affiliate'
import { ButtonBlue, ButtonGreen } from '../../common/ButtonsGenerics'
import { SnackBarGeneric } from '../../common/SnackBarGeneric'

const FormReset = () => {

    const [mail, setMail] = useState('')
    const [loading, setLoading] = useState(false)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const handleMail = (e) => {
        setMail(e.target.value)
    }

    const handleSumbit = async () => {
        const body = {
            email: mail
        }
        setLoading(true)
        const req = await resetPassword(body)
        setLoading(false)
        if (req.error) {
            openSnackBar(
                "error",
                "¡Ocurrió un error!"
            );
        }
        else{
            openSnackBar(
                "success",
                "¡Se envió un mail para restablecer la contraseña!"
            );
        }
    }

    return (
        <Wrapper>
            <TextField value={mail} onChange={handleMail} label="Ingresar correo electrónico"/>
            <ButtonBlue disabled={!mail || loading ? true : false} onClick={handleSumbit}>{!loading ? 'Resetear' : 'Cargando'}</ButtonBlue>
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </Wrapper>
    )
}

export default FormReset

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 12px 0;
`