import React from "react";
import { Redirect, Route } from "react-router-dom";

import Layout from "../components/shared/layout/Layout";
import { connect } from "react-redux";
// import Layout from "../components/shared/layout/Layout";

const PrivateRoute = (props) => {
	const { component: Component, isAuthenticated, type = false, ...rest } = props;

	const renderLayout = (type, props) => {
		const cases = {
			common: <Layout>
				<Component {...props} />
			</Layout>,
			noLayout: <Component {...props} />
		}
		return cases[type || 'common']
	}

	// RETURN
	return (
		<Route
			{...rest}
			component={(props) =>
				isAuthenticated ? (
					renderLayout(type, props)
				) : (
					<Redirect to="/auth/login" />
				)
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.auth.authToken,
	user: state.auth.user,
});

export default connect(mapStateToProps)(PrivateRoute);
