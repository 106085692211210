import React from "react";
import styled from "styled-components";
import CardGeneric from "../../components/common/CardGeneric";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { ContactForm } from "../../components/templates/contact/ContactForm";

const Contact = () => {
    return <ContactWrapper>
         <CardGeneric title={"Contacto"} icon={<ContactMailIcon/>}>
            <ContactForm/>
        </CardGeneric>
    </ContactWrapper>
}

export default Contact;

const ContactWrapper = styled.div`
    width: 100%;
`