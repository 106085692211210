import { Button } from "@material-ui/core"
import styled from "styled-components"

export const ButtonBlue = styled(Button)`
    background-color: #528bab;
    color: white;
    margin: .1rem .3rem;
    :hover{
        background-color: #528b99;
    }
    :disabled{
        background-color: #999999;
    }
`

export const ButtonGreen = styled(Button)`
    background-color: #7dbd6a;
    color: white;
    margin: .1rem .3rem;
    :hover{
        background-color: #7dbd99;
    }
    :disabled{
        background-color: #999999;
    }
`

export const ButtonRed = styled(Button)`
    background-color: #e34d4b;
    color: white;
    margin: .1rem .3rem;
    :hover{
        background-color: #e34d4b99;
    }
    :disabled{
        background-color: #999999;
    }
`

export const ButtonTransparent = styled(Button)`
    background-color: #00000000;
    color: black;
    margin: .1rem .3rem;
    :hover{
        background-color: #00000015;
    }
    :disabled{
        background-color: #999999;
    }
`