import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { Tooltip, withStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';

export const MenuClose = (props) => {
    const { icon, submenus, title } = props

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Container onClick={handleClick}>
                <FakeTooltip arrow title={title} placement="right">

                    {icon}
                </FakeTooltip>
            </Container>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {submenus.map((menu, i) => {
                    return <MenuItem key={i}><LinkFake to={`/${menu.to}`}> {menu.name}</LinkFake> </MenuItem>
                })}
            </Menu>
        </div>
    );
}

const FakeTooltip = withStyles((theme) => ({
    arrow: {
        color: "#303030",
    },
    tooltip: {
        backgroundColor: "#303030",
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: "0.2em 0.5em",
    },
}))(Tooltip);

export default MenuClose;

const Container = styled.div`
    width: calc( 100% - 1rem );
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-radius: 3px;
    margin: .5rem 0;
    svg, span {
            color: white;
    }
    span {
        color: white;
    }

    :hover {
        background-color: #4e7991;
    }

    ${props => props.active ? (
        `
            :hover{
                background-color: white;
                svg, span {
                    color: #2f424d;
                }
                span {
                    color: white;
                }
            }
            background-color: white;
            svg, span {
                color: #2f424d;
            }
            span {
                color: white;
            }
            
            `
    )
        :
        null
    }
    
`

const LinkFake = styled(Link)`
    text-decoration: none;
    color: #2f424d;
`