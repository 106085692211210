import { validateDocument } from "../../../helpers/InputValidate.helper";
import { Button, Input, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";


function DniForm(props) {

    //props
    const { user, setUser, handleDniSubmit } = props;

    //states
    const [userError, setUserError] = useState(true)


    // Functions
    const handleUser = (e) => {
        setUserError(validateDocument(e.target.value))
        setUser(e.target.value)
    }


    const handleSubmitUser = () => {
        handleDniSubmit('pendigRequests')
    }


    return (
        <FormLoginWrapper>
            <Form>
                <TextField type="number" value={user} helperText={userError ? "" : "Cantidad de digitos inválido"} error={!userError} onChange={handleUser} label="Documento" />
            </Form>
            <Button disabled={!userError} onClick={handleSubmitUser} variant="contained" color="primary">
                Ver solicitudes de pago
            </Button>
        </FormLoginWrapper>
    )
}

export default DniForm

const FormLoginWrapper = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Form = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
`

const InputFake = styled(Input)`
    margin: 2rem 0;
`

const ButtonLoading = styled(Button)`
    display: flex;
    width: 150px;
    justify-content: space-between;`