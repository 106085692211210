import axios from "axios";

export async function getRefunds(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/reintegro/reintegros_by_afiliado?afiliado_id=${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getRefundsByDate(id,{initial_date, finish_date}) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/reintegro/search/?afiliado_id=${id}&estado=all&fecha_fin=${finish_date}&fecha_inicio=${initial_date}`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function deleteRefundById(id, user) {
	try {
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/reintegro/anular/${id}?afiliado_id=${user}`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function createRefunds(body) {
	try {
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/reintegro`, body);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getRefundById(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/reintegro/${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getTypeRefunds() {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/reintegro/tipo_reintegro`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getTypeFactura() {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/reintegro/tipo_factura`);
        return response.data
    } catch (error) {
        return {error}
    }
}