import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from '../../../../hooks/useForm';
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { createOrder } from '../../../../redux/api/orders';
import { getProvider } from '../../../../redux/api/utils';
import { ButtonGreen } from '../../../common/ButtonsGenerics';
import InputFile from '../../../common/InputFile';
import PageLoading from '../../../common/PageLoading';
import InfoUser from '../../../common/user/InfoUser';
import { SnackBarGeneric } from "../../../common/SnackBarGeneric";
import { Text } from '../../../common/Text';
import { createRefunds, getTypeFactura, getTypeRefunds } from '../../../../redux/api/refunds';

const NewRefunds = props => {

    const { handleRefresh } = props

    const [affiliate, setAffiliate] = useState(null);
    const [providers, setProviders] = useState(null);
    const [typeFac, setTypeFac] = useState(null)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)

    const [loading, setloading] = useState(false)

    // Form
    const { user, family } = useSelector(state => state.auth)
    const initialForm = {
        afiliado: null,
        nombre: user?.nombre + '' + user?.apellido,
        dni: user?.numero_documento,
        cbu: '',
        email_contacto: user.email,
        tel_contacto: user.mobile || user.phone,
        tipo_reintegro_id: null,
        factura_cuit: null,
        factura_tipo: null,
        factura_nro: null,
        comentario: null,
    }
    const [form, handleFormInput, status] = useForm(initialForm)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const affiliates = [user, ...family]

    useEffect(() => {
        getProviderComp()
        getTypeFac()
    }, [])

    const handleChangeAffiliate = (event) => {
        setAffiliate(affiliates.filter(aff => aff.afiliado_id === event.target.value)[0]);
    };

    const getProviderComp = async () => {
        const requestProvider = await getTypeRefunds()
        if (!requestProvider.error) {
            setProviders(requestProvider)
        }
    }

    const getTypeFac = async () => {
        const requestType = await getTypeFactura()
        if (!requestType.error) {
            setTypeFac(requestType)
        }
    }

    const handleB64File = (files) => {
        // setFileName(name)
        setFile(files)
    }

    const handleSubmit = async () => {
        setloading(true)

        const request = await createRefunds({...form, afiliado_id: form.affiliate, images: file})
        if (request.error) {
            openSnackBar(
                "error",
                "Error al crear la solicitud de reintegro"
            );
        }
        else {
            openSnackBar(
                "success",
                "Se creó correctamente"
            );
            setTimeout(() => {
                handleRefresh()
            }, 1500);
        }
        setloading(false)
    }

    return <NewRefundsWrapper>
        <Left>
            <FormControlFake>
                <InputLabel id="1">Seleccione un afiliado *</InputLabel>
                <SelectFake
                    labelId="1"
                    value={form.affiliate}
                    onChange={e => {
                        handleChangeAffiliate(e)
                        handleFormInput(e)
                    }}
                    name="affiliate"
                >
                    {affiliates.map(member => <MenuItem value={member.afiliado_id}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>
            <Grid>
                <TextFieldFake
                    label="Nombre completo *"
                    type="text"
                    name="nombre"
                    value={form?.nombre}
                    onChange={handleFormInput}
                />
                <TextFieldFake
                    label="DNI *"
                    type="text"
                    name="dni"
                    value={form?.dni}
                    onChange={handleFormInput}
                />
                <TextFieldFake
                    label="CBU *"
                    type="text"
                    name="cbu"
                    value={form?.cbu}
                    onChange={handleFormInput}
                />
                <TextFieldFake
                    label="Correo electronico *"
                    type="text"
                    name="email_contacto"
                    value={form.email_contacto}
                    onChange={handleFormInput}
                />
                <TextFieldFake
                    label="Teléfono de contacto *"
                    type="text"
                    name="tel_contacto"
                    value={form.tel_contacto}
                    onChange={handleFormInput}
                />

                {!!providers && (
                    <FormControlFake>
                        <InputLabel id="1">Tipo de reintegro *</InputLabel>
                        <SelectFake
                            disabled={!providers}
                            onChange={e => {
                                handleFormInput(e)
                            }}
                            labelId="1"
                            name="tipo_reintegro_id"
                            value={form.tipo_reintegro_id}
                        >
                            {providers.map(provider => <MenuItem value={provider.id}>{`${provider.name}`}</MenuItem>)}
                        </SelectFake>
                    </FormControlFake>
                )}
            </Grid>
            <TextFieldFake
                id="date"
                label="Comentario *"
                type="text"
                multiline
                rows={4}
                name="comentario"
                value={form.comentario}
                onChange={handleFormInput}
            />
        </Left>
        <Right>
            {affiliate !== null && (
                <InfoUser affiliate={affiliate} />
            )}
            <br/>
            <Text fontWeight="600" color="#333333" fontSize="1.3rem">Factura</Text>
            <TextFieldFake
                label="Cuit *"
                type="text"
                name="factura_cuit"
                value={form.factura_cuit}
                onChange={handleFormInput}
            />
            {!!typeFac && (
                    <FormControlFake>
                        <InputLabel id="1">Tipo de factura *</InputLabel>
                        <SelectFake
                            disabled={!typeFac}
                            onChange={e => {
                                handleFormInput(e)
                            }}
                            labelId="1"
                            name="factura_tipo"
                            value={form.factura_tipo}
                        >
                            {typeFac.map(provider => <MenuItem value={provider.id}>{`${provider.name}`}</MenuItem>)}
                        </SelectFake>
                    </FormControlFake>
                )}
            <TextFieldFake
                label="Número *"
                type="text"
                name="factura_nro"
                value={form.factura_nro}
                onChange={handleFormInput}
            />
            <Text fontWeight="600" color="#333333" fontSize="1.3rem">Comprobante</Text>
            <InputFile handleB64File={handleB64File} />
        </Right>
        <Footer>
            {!loading ?
                (<ButtonGreen onClick={handleSubmit} disabled={status}>Solicitar reintegro</ButtonGreen>) :
                (<PageLoading h="auto" w="100%" />)}
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </NewRefundsWrapper>
}

export default NewRefunds;

const NewRefundsWrapper = styled.div`
    width: auto;
    display: grid;
    display: grid;
    grid-template-areas: "left right"
                         "footer footer";
    gap: .5rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-areas: "left" 
                            "right"
                            "footer";
    }
`
const Grid = styled.div`
    width: auto;
    display: grid;
    gap: 1rem;
    margin: 1rem 0;
`

const Left = styled.div`
    grid-area: left;
    width: 100%;
`

const Right = styled.div`
    grid-area: right;
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
    margin: 1rem 0;
`

const Footer = styled.div`
    grid-area: footer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`