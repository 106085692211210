// import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { createPendingPayFromPaymentRequestOnSisad, getPendingPaymentRequestsFromSisad } from '../../../redux/api/account'
import PageLoading from '../../common/PageLoading'
// import { Autocomplete } from '@material-ui/lab';
import TableGeneric from '../../common/TableGeneric'
import { ButtonBlue, ButtonGreen, ButtonRed } from '../../common/ButtonsGenerics'
import { formatCurrency } from '../../../helpers/currency.format.helper'
import { ButtonTransparent } from "../../../components/common/ButtonsGenerics";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



const PendingList = (props) => {

    const { user: afiliado_id, handlePagePay, gotoPage } = props

    const columns = [
        // { id: 'id', label: 'N° Factura' },
        { id: 'name', label: 'Descripción' },
        { id: 'fecha', label: 'Fecha' },
        { id: 'total', label: 'Monto total' },
        { id: 'residual', label: 'Saldo' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const [pays, setPays] = useState(null)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        !pays && getPays()
    }, [pays])

    const getPays = async () => {
        const req = await getPendingPaymentRequestsFromSisad(afiliado_id)
        if (req.error) {
            alert('Ocurrio un error')
        }
        if (req[0]?.statusCode === 404) {
            setPays([])
            return
        }
        setPays(req)
    }

    const handleSelect = value => {
        console.log(value)
        const result = pays.map(item => {
            if (item.id === value.id) {
                return { ...item, checked: true }
            }
            else {
                return item
            }
        })
        setTotal(total + value.importeTotal)
        setPays(result)
    }

    const handleQuit = value => {
        const result = pays.map(item => {
            if (item.id === value.id) {
                return { ...item, checked: false }
            }
            else {
                return item
            }
        })
        setTotal(total - value.importeTotal)
        setPays(result)
    }


    const tableRender = (array) => {
        if (array) {
            return array.map(item => ({
                ...item,
                name: item.detalle,
                fecha: new Date(item.fecha).toLocaleDateString(),
                total: `$${formatCurrency(item.importeTotal.toFixed(2))}`,
                residual: `$${formatCurrency(item.importeTotal.toFixed(2))}`,
                acciones: !!item.checked ? <ButtonRed onClick={() => handleQuit(item)}>Quitar</ButtonRed> : <ButtonGreen onClick={() => handleSelect(item)}>Seleccionar</ButtonGreen>
            }))
        }
        else {
            return array
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        const body = { pagoSolicitudArr: pays.filter(item => !!item.checked).map(item => item.id) }
        body.dni = afiliado_id;
        const req = await createPendingPayFromPaymentRequestOnSisad(body)
        setLoading(false)
        if (req.error) {
            alert('Ocurió un error al generar el pago')
            return
        }
        else {
            handlePagePay(req.amount_total.toFixed(2), req.id)
        }
    }

    if (!pays || loading) {
        return <PageLoading w="100%" h="100px" />
    }

    return (
        <PaysWrapper>
            <ArrowBackContainer>
                <ButtonTransparent onClick={() => gotoPage(null)}><ArrowBackIcon /><NameButton>Atras</NameButton></ButtonTransparent>
            </ArrowBackContainer>
            <TableGeneric columns={columns} rows={tableRender(pays)} pageSize={10} />
            <TotalContainer>Total a pagar: ${formatCurrency(total.toFixed(2))}</TotalContainer>
            {pays.filter(item => !!item.checked).length > 0 && total > 0 && <ButtonBlue onClick={handleSubmit}>Ir a pagar</ButtonBlue>}
        </PaysWrapper>
    )
}

export default PendingList

const PaysWrapper = styled.div`
width: 100%;
`

const TotalContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: bold;
`
const ArrowBackContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const NameButton = styled.div`
    margin-left: 1rem;
    font-size: 1rem;
`