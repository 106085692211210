import axios from 'axios';

// Function to create an API instance for Public API
const createPublicApiInstance = () => {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_PUBLIC_API}`,
  });
  instance.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_TOKEN_PUBLIC_API}`;
  return instance;
};

export default createPublicApiInstance;
