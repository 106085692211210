import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from '../../../hooks/useForm';
import { useSnackBar } from '../../../hooks/useSnackBar';
import { ButtonGreen } from '../../common/ButtonsGenerics';
import InputFile from '../../common/InputFile';
import PageLoading from '../../common/PageLoading';
import InfoUser from '../../common/user/InfoUser';
import {SnackBarGeneric} from "../../common/SnackBarGeneric";
import { createAudit, getTypeAuditService } from '../../../redux/api/audit';


const FormAudit = props => {

    const { handleRefresh } = props

    const { user, family } = useSelector(state => state.auth)
    const [affiliate, setAffiliate] = useState(user);
    const [audits, setAudits] = useState(null);
    const [file, setFile] = useState(null)

    const [loading, setloading] = useState(false)
    
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    
    const affiliates = [user, ...family]  
    const initialForm = {
        affiliate: user.afiliado_id,
        place: null,
        email: user.email || null,
        type_audit: null,
        phone: user.phone || user.mobile || null,
        message: null
    }
    const [form, handleFormInput, status] = useForm(initialForm)

    useEffect(() => {
        getTypeAudit()
    }, [])

    const getTypeAudit = async () => {
        const req = await getTypeAuditService()
        setAudits(req)
    }

    const handleChangeAffiliate = (event) => {
        setAffiliate(affiliates.filter(aff => aff.afiliado_id === event.target.value)[0]);
    };

    const handleB64File = (files) => {
        setFile(files)
    }
    

    const handleSubmit = async () => {
        setloading(true)
        let data = {
            afiliado_id: form.affiliate || null,
            phone: form.phone || '',
            type_audit: form.type_audit || null,
            email: form.email || '',
            message: form.message || '',
            place: form.place || '',
            images: file || [],
        }
        const request = await createAudit(data)
        if (request.error) {
            openSnackBar(
                "error",
                "Error en la auditoria médica"
            );
        }
        else{
            openSnackBar(
                "success",
                "Se creó correctamente"
            );
            setTimeout(() => {
                handleRefresh()
            }, 1500);
        }
        setloading(false)
    }

    return <FormAuditWrapper>
        <Left>
            <FormControlFake>
                <InputLabel id="1">Seleccione un afiliado *</InputLabel>
                <SelectFake
                    labelId="1"
                    value={form.affiliate}
                    onChange={e => {
                        handleChangeAffiliate(e)
                        handleFormInput(e)
                    }}
                    name="affiliate"
                >
                    {affiliates.map(member => <MenuItem value={member.afiliado_id}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>
            <Grid>
                <TextField name="email" onChange={handleFormInput} label="Correo electrónico *" value={form.email}/>
                <TextField name="phone" onChange={handleFormInput} label="Teléfono de contacto *" value={form.phone}/>
                {!!audits && (
                    <FormControlFake>
                        <InputLabel id="1">Tipo de auditoria *</InputLabel>
                        <SelectFake
                            disabled={!audits}
                            onChange={handleFormInput}
                            labelId="1"
                            name="type_audit"
                            value={form.type_audit}
                        >
                            {audits.map(au => <MenuItem value={au.id}>{`${au.name}`}</MenuItem>)}
                        </SelectFake>
                    </FormControlFake>
                )}
                <TextField name="place" onChange={handleFormInput} label="Lugar (donde se efectua práctica o consulta) *" value={form.place}/>
                <TextField
                    id="date"
                    label="Comentario *"
                    type="text"
                    multiline
                    rows={4}
                    name="message"
                    value={form.message}
                    onChange={handleFormInput}
                />
            </Grid>
        </Left>
        <Right>
            {affiliate !== null && (
                <InfoUser affiliate={affiliate} />
            )}
            <InputFile handleB64File={handleB64File}/>
        </Right>
        <Footer>
            {!loading ? 
            (<ButtonGreen onClick={handleSubmit} disabled={!form.message || !form.email || !form.phone || !form.place || !form.affiliate ? true : false}>Solicitar auditoría</ButtonGreen>):
            (<PageLoading h="auto" w="100%"/>)}
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </FormAuditWrapper>
}

export default FormAudit;

const FormAuditWrapper = styled.div`
    width: auto;
    display: grid;
    display: grid;
    grid-template-areas: "left right"
                         "footer footer";
    gap: .5rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-areas: "left" 
                            "right"
                            "footer";
    }
`
const Grid = styled.div`
    width: auto;
    display: grid;
    gap: 1rem;
    margin: 1rem 0;
`

const Left = styled.div`
    grid-area: left;
    width: 100%;
`

const Right = styled.div`
    grid-area: right;
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
    margin: 1rem 0;
`

const Footer = styled.div`
    grid-area: footer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`