import axios from "axios";
import createPublicApiInstance from '../api';

export async function getCurrentAccount(id) {
	try {
		const apiInstance = createPublicApiInstance(); 
		if (!apiInstance) {
			throw new Error('API instance is not available');
		}
		
		const response = await apiInstance.get(`/movimiento-cuenta-corriente/${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getAccountSearch(id, dates) {
	try {
		const apiInstance = createPublicApiInstance(); 
		if (!apiInstance) {
			throw new Error('API instance is not available');
		}
		
		const body = {
			fecha_hasta: dates.finish_date,
			afiliado_id: id,
			fecha_desde: dates.initial_date
		}

		const response = await apiInstance.post(`/movimiento-cuenta-corriente/search`,body);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getPaysDebtFromSisad(id) {
	try {
		const apiInstance = createPublicApiInstance(); 
		if (!apiInstance) {
			throw new Error('API instance is not available');
		}
		
		const response = await apiInstance.get(`/pendientes-pago/${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}
export async function getPendingPaymentRequestsFromSisad(id) {
	try {
		const apiInstance = createPublicApiInstance(); 
		if (!apiInstance) {
			throw new Error('API instance is not available');
		}
		
		const response = await apiInstance.get(`/pago-solicitud/pendientes/${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function createPay(body) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/pago`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function createPendingPayonSisad(body) {
	try {
		const apiInstance = createPublicApiInstance(); 
		if (!apiInstance) {
			throw new Error('API instance is not available');
		}
		
		const response = await apiInstance.post(`/pago/crear-pendiente`,body);

		return response.data
	} catch (error) {
		return { error }
	}
}

export async function createPendingPayFromPaymentRequestOnSisad(body) {
	try {
		const apiInstance = createPublicApiInstance(); 
		if (!apiInstance) {
			throw new Error('API instance is not available');
		}
		
		const response = await apiInstance.post(`/pago-solicitud/crear-pago-pendiente`,body);

		return response.data
	} catch (error) {
		return { error }
	}
}