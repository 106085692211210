import React from "react";
import styled from "styled-components";

const ErrorPage = props => {
    const { message = "¡Ocurrió un error!", button = null } = props

    return <ErrorpageWrapper>
        <Message>{message}</Message>
        {button}
    </ErrorpageWrapper>
}

export default ErrorPage;

const ErrorpageWrapper = styled.div`
    width: calc(100% - 6rem);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
`

const Message = styled.div`
    font-size: 1rem;
    font-weight: 700;
    color: #528bab;
    margin-bottom: 1rem;
    text-transform: uppercase;
`