import React, { useState } from "react";
import styled from "styled-components";
import LocalPharmacyRoundedIcon from '@material-ui/icons/LocalPharmacyRounded';
import CardGeneric from "../../components/common/CardGeneric";
import StockForm from "../../components/templates/pharmacy/StockForm";
import TableGeneric from "../../components/common/TableGeneric";
import DownloadStock from "../../components/ui/organisms/pharmacy/DownloadStock";
import SearchGeneric from "../../components/common/SearchGeneric";


const StockPharmacy = () => {

    const [result, setResult] = useState(null)
    const [filtered, setFiltered] = useState(null);

    const handleResultForm = result => {
        setResult(null)
        setTimeout(() => {
            setResult(result)
        }, 100);
    } 

    const columns = [
        { id: 'troquel', label: 'Troquel' },
        { id: 'articulo', label: 'Artículo' },
        { id: 'presentacion', label: 'Presentacion' },
        { id: 'stock', label: 'Stock' },
    ]

    const handleFilter = filtered => {
        setFiltered(filtered)
    }

    return <StockPharmacyWrapper>
        <CardGeneric title={"Stock de medicamentos"} icon={<LocalPharmacyRoundedIcon/>}>
            <StockForm handleResultForm={handleResultForm}/>
        </CardGeneric>
        {result && (
            <CardGeneric title={"Resultados"} icon={<LocalPharmacyRoundedIcon/>}>
                <Header>
                    <SearchGeneric title="Buscar medicamentos" handleValues={handleFilter} filterNames={['troquel', 'articulo', 'stock']} values={result} />
                    <DownloadStock print={filtered || result} />
                </Header>
                <TableGeneric columns={columns} rows={filtered || result} pageSize={10}/>
            </CardGeneric>
        )}
    </StockPharmacyWrapper>
}

export default StockPharmacy;

const StockPharmacyWrapper = styled.div`
    width: 100%;
`

const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`