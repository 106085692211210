import axios from "axios";
import createPublicApiInstance from '../api';

export async function loginUser(payload) {

	const originalResponse = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/afiliado/login`, {	
		numero_documento: parseInt(payload.user,10),
		password: payload.password,
	});

	//Reemplazamos la info del afiliado por la que retorna Sisad.
	const apiInstance = createPublicApiInstance(); 
	if (!apiInstance) {
		throw new Error('API instance is not available');
	}

	const sisadaResult = await apiInstance.get(`/afiliado/afiliado-y-familiares/${originalResponse.data.user.afiliado_id}`)
	originalResponse.data.user = sisadaResult.data;
	return originalResponse;
}

export async function registerForm(body) {
	try {
		const request = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/user/search_user`, body);
		return request.data
	} catch (error) {
		return { error }
	}
}

export async function registerPassword(body, token) {
	try {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
		const request = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/user/create_user`, body, config);
		return request.data
	} catch (error) {
		return { error }
	}
}